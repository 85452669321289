import React, { useCallback, useMemo, useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { MuiConfig } from '@react-awesome-query-builder/mui';
import {
  Actions,
  Builder,
  BuilderProps,
  Config,
  ImmutableTree,
  Query,
  Utils as QbUtils,
  JsonTree
} from '@react-awesome-query-builder/ui';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import AddIcon from '@mui/icons-material/Add';
import { v4 as uuidv4 } from 'uuid';

import { FormWrapper, InputField } from 'components';
import { ActionEditor } from 'components/RuleEngine/ActionEditor';
import { DEFAULT_RULE_ORDER } from 'enums';
import {
  generatePreconditionConfigFields,
  generatePreconditionConfigOperators,
  generatePreconditionConfigTypes,
  generatePreconditionConfigWidgets,
  generateActionOptions,
  generateCustomFunctions,
} from 'helpers';
import { initAction } from 'services/ruleEngine';
import { Action, ExpressionEditorConfig, Option, Rule } from 'types';

import '@react-awesome-query-builder/ui/css/compact_styles.css';
import { useStyles } from './CreateUpdateRuleModal.css';
import { getValidationSchema } from './validation';

const InitialConfig = MuiConfig;
InitialConfig.settings.defaultSelectWidth = '400px';

type CreateUpdateRuleModalProps = {
  rule?: Rule;
  onCancel: () => void;
  handleCreateEditRule?: (rule: Rule) => void;
  rules: Rule[];
  ruleEditorConfiguration: ExpressionEditorConfig;
};

export const CreateUpdateRuleModal = ({
  rule = null,
  onCancel,
  handleCreateEditRule = null,
  rules,
  ruleEditorConfiguration,
}: CreateUpdateRuleModalProps) => {

  const classes = useStyles();
  const isReadOnly: boolean = useMemo(() => handleCreateEditRule === null, [handleCreateEditRule]);
  const preconditionConfig : Config = useMemo(() => {
    const cnf: Config = {
      ...InitialConfig,
      widgets: generatePreconditionConfigWidgets(),
      types: generatePreconditionConfigTypes(),
      fields: generatePreconditionConfigFields(ruleEditorConfiguration),
      operators: generatePreconditionConfigOperators(),
      funcs: generateCustomFunctions(),
    };
    if (isReadOnly) {
      cnf.settings = {
        ...cnf.settings,
        immutableGroupsMode: true,
        immutableFieldsMode: true,
        immutableOpsMode: true,
        immutableValuesMode: true,
        canReorder: false,
        canRegroup: false,
      };
    }
    return cnf;
  }, [ ruleEditorConfiguration, isReadOnly ]);

  const preconditionJsonTree : ImmutableTree = useMemo(() => {
    //if needed we use generateInitialQuery
    return rule ? QbUtils.loadFromJsonLogic(rule.test, preconditionConfig) : QbUtils.loadTree( {id: QbUtils.uuid(), type: 'group'} as JsonTree);
  }, [preconditionConfig]);

  const [ preconditionState, setPreconditionState ] = useState({
    tree: QbUtils.checkTree(
      preconditionJsonTree,
      preconditionConfig
    ),
    config: preconditionConfig,
  });

  const queryManipulationActions = useRef<Actions>(null);
  const emptyRuleInitialized = useRef<boolean>(false);

  //when the query builder has been instanced and the action set is available
  //and we are creating the new rule
  //we are adding the empty query rule to existing JsonTree
  useEffect(() => {
    if (!emptyRuleInitialized.current && queryManipulationActions.current && !rule && preconditionState.tree) {
      const rootPath = [preconditionState.tree.get('id') as string];
      (queryManipulationActions.current as Actions).addRule(rootPath, {field: ''});
      emptyRuleInitialized.current = true;
    }
  }, [queryManipulationActions.current]);

  const onChangePrecondition = useCallback(
    (immutableTree: ImmutableTree, config: Config) => {
      const errors = QbUtils.jsonLogicFormat(immutableTree, preconditionConfig).errors;
      if (errors && errors.length > 0) {
        toast.error('Rule query not permitted and will not be saved!');
        errors.forEach( msg => toast.error(msg));
        return;
      }

      setPreconditionState((prevState) => ({
        ...prevState,
        tree: immutableTree,
        config: config,
      }));
    },
    []
  );

  const actionsOptions : Option[] = useMemo(() => {
    return generateActionOptions(ruleEditorConfiguration);
  }, [ruleEditorConfiguration]);

  const [ actionsState, setActionsState ] = useState<Action[]>(rule?.actions?.length >0 ? rule.actions : [initAction(ruleEditorConfiguration.actions.find(el => el.name === actionsOptions[0].id))]);

  const onAddAction = () => {
    const newAction = initAction(ruleEditorConfiguration.actions.find(el => el.name === actionsOptions[0].id), actionsState[actionsState.length - 1].priority+1);
    setActionsState([ ...actionsState, newAction ]);
  };

  const onUpdateAction = (action: Action) => {
    setActionsState(actionsState.map(a => a.priority === action.priority ? action : a));
  };

  const onDeleteAction = (itemToDelete: Action) => {
    const newList = actionsState.filter(i => itemToDelete.priority !== i.priority);
    newList.sort((e1: Action, e2: Action) => e1.priority - e2.priority);
    setActionsState(newList);
  };

  const initialValues = {
    name: rule?.name || '',
    priority: rule?.priority || 1,
    order: rule?.order || DEFAULT_RULE_ORDER
  };

  const onSubmitRule = (values: Rule) => {
    const payload: Rule = {
      id: rule ? rule.id : uuidv4(),
      priority: Number(values.priority),
      order: values.order,
      name: values.name,
      test: QbUtils.jsonLogicFormat(preconditionState.tree, preconditionConfig).logic,
      actions: actionsState,
    };
    if (!payload.test) {
      toast.error('Rule query is not defined!');
      return;
    }
    if (payload.actions.length === 0) {
      toast.error('Rule action is not defined!');
      return;
    }
    if (!handleCreateEditRule) {
      toast.error('HandleCreateEditRule method is not set!');
      return;
    }
    handleCreateEditRule(payload);
    onCancel();
  };

  const renderBuilder = useCallback(
    (props: BuilderProps) => {
      //during the process of query builder instancing
      //we are acquiring an programmable action manipulation set for builder
      queryManipulationActions.current = props.actions;
      return (
        <div className='query-builder-container'>
          <div className='query-builder' style={{ margin: '0px' }}>
            <p className={classes.title}>If following preconditions are met</p>
            <div className={classes.builderOverrides}>
              <Builder {...props} />
            </div>
          </div>
        </div>
      );
    },
    []
  );

  return (
    <FormWrapper<{ priorityOrder: string; }>
      initialValues={initialValues}
      validationSchema={getValidationSchema(rules, rule)}
      validateOnChange
      validateOnMount
      primaryButtonLabel={'Save'}
      onCancel={onCancel}
      onSubmit={isReadOnly ? null : onSubmitRule}
      noValidate>
      {({ errors }) => (
        <>
          <InputField id='name' label='Name' readOnly={isReadOnly} />
          {rule &&
            <>
              <Grid container columnSpacing={2} sx={{marginTop: 2}}>
                <Grid item xs={6}>
                  <InputField id='priority' label='Priority' type='number' readOnly={isReadOnly} />
                </Grid>
                <Grid item xs={6}>
                  <InputField id='order' label='Order' readOnly={isReadOnly} />
                </Grid>
              </Grid>
              {errors?.priorityOrder && <div className={classes.errorMsg}>{errors.priorityOrder}</div>}
            </>
          }
          <Query
            {...preconditionConfig}
            value={preconditionState.tree}
            onChange={onChangePrecondition}
            renderBuilder={renderBuilder} />
          {/* <div className='query-builder-result'>
            <div>
              JsonLogic:{' '}
              <pre>
                {JSON.stringify(QbUtils.jsonLogicFormat(preconditionState.tree, preconditionState.config))}
              </pre>
            </div>
          </div> */}
          <p className={classes.title}>Then the actions will be executed</p>
          <div className='query-builder-container'>
            <Tooltip title='Add Action' className={classes.buttonRight}>
              <IconButton onClick={onAddAction} disabled className={classes.iconButton}>
                <AddIcon />
              </IconButton>
            </Tooltip>
            <div className={classes.buttonContainer}>
              {
                actionsState.map((action) => (
                  <ActionEditor
                    key={action.name+action.priority}
                    config={ruleEditorConfiguration}
                    action={action}
                    actionsOptions={actionsOptions}
                    canDelete={actionsState.length>1}
                    onUpdate={onUpdateAction}
                    onDelete={onDeleteAction}
                    isReadOnly={isReadOnly} />
                ))}
            </div>
          </div>
        </>
      )}
    </FormWrapper>
  );
};
